<template lang="html">
  <div>
    <Recorder @complate="RecorderComplate"></Recorder>
    <UploadBox ref="UploadBox" action="/Api/My/Files/Upload"
               :param="param"
               :multiple="true"
               :maxSize="2"
               :allowExtensions="/(.aac|.mp3)$/i"
               :partSizeMB="10"
               :maxUpper="3"
               :uploadFinished="search"
               previewIcon="fas fa-file-audio"
               :changeName="changeName"
               :showButton="false">
      <template #item="{file,status,size,percent}">
        <div style="flex:none">
          <span class="info-box-text">{{status}} </span>
          <span class="info-box-number">{{size}}<span v-if="status=='正在上传'">,{{percent?percent:0}}%</span></span>
        </div>
        <div style="flex:auto">
          <audio style="width:100%" :src="(file||{}).audioUrl" controls></audio>
        </div>
      </template>
    </UploadBox>
  </div>
</template>

<script>
  import { UploadBox, Recorder } from 'common'
  //import UploadBox from '@/components/UploadBox'
  //import Recorder from '@/components/Recorder'

  export default {
    components: {
      UploadBox,
      Recorder
    },
    data() {
      return {
        audios: [],
        param: {}
      }
    },
    created() {
    },
    computed: {
    },
    methods: {
      RecorderComplate(voice) {
        var file = voice.file
        file.name = `${this.$moment(new Date()).format('YYYYMMDDHHmmss')}时长${voice.timelong}ms.mp3`
        file.audioUrl = voice.url
        this.audios.push(voice)
        this.$refs.UploadBox.$refs.Upload.addFile({ target: { files: [file] } })
      },
      changeName(item, res) {
        var _this = this;
        if (res) {
          console.log(item, res)
          var data = { ...res };
          data.name = item.title;
          console.log(data)
          this.$axios.put('/Api/My/Files/' + data.guid, data).then(function (res) {
            if (res.status == 200) {
              _this.search()
            }
          })
        }
      },

    },
  }
</script>

<style scoped>
</style>
